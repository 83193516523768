.navbar {
  background: transparent;
  height: 80px;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all ease 0.5s;
}

.navbar--second {
  background: rgb(255, 255, 255);
  height: 80px;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all ease 0.5s;
}

.navbar--second .nav-links {
  color: black;
}
.navbar--second .nav-links:hover {
  border-bottom: 4px solid var(--accent);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  height: auto;
  width: 410px;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: end;
}

.nav-item {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  color: #fff;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  transition: color 1s;
  color: #fff;
}

#b {
  color: black;
}
.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.social-icon {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.icon-wrapper {
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.social-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  max-width: 128px;
  height: 36px;
  margin-left: 42px;
}
@media screen and (max-width: 1248px) {
  .navbar {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1140px) {
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .navbar--second {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-menu.active .nav-links {
    color: white;
  }

  .nav-item {
    display: flex;
    justify-content: center;
  }

  .social-wrapper {
    justify-content: center;
    margin: 0;
  }

  .navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .navbar-logo {
    width: 220px;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
