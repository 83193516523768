.empresa {
  width: 100%;
}

.empresa-about-section {
  width: 100%;
  background: var(--grey);
  padding-bottom: 80px;
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1330px;
}

.about-title {
  color: var(--accent);
  font-size: 38px;
}

.about-content-wrapper {
  margin-top: 42px;
  display: flex;
  flex-direction: row;
}

.about-leftp p {
  color: white;
  font-size: 18px;
  line-height: 41px;
}

.about-rightp p {
  color: white;
  font-size: 18px;
  line-height: 38px;
  width: 45vw;
}

.divider {
  margin-left: 40px;
  margin-right: 40px;
  border-left: 1px solid #707070;
  height: 340px;
}

@media screen and (max-width: 1000px) {
  .divider {
    display: none;
  }

  .about-content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .about-leftp {
    width: 100%;
  }

  .about-rightp {
    width: 100%;
  }

  .about-rightp p {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .about-title {
    font-size: 32px;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .empresa-about-section {
    padding-left: unset;
    padding-right: unset;
  }
}
