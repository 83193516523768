.plogo {
  height: 218px;
  width: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid gray;
}

.plogo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .plogo {
    height: 150px;
    width: 150px;
  }
}
@media screen and (max-width: 725px) {
  .plogo {
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 500px) {
  .plogo {
    height: 150px;
    width: 150px;
    margin-top: 25px;
  }
}
