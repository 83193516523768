  .hero-container {
    background: url('/public/images/16.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-repeat: no-repeat;
  }


  .hero-wrapper{
    position: absolute;
    bottom: 125px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
  }

  .--b{
    margin-bottom: 45px;
  }


  @media screen and (max-width: 960px) {
    .hero-title{
      font-size: 40px;
    }
  }
  @media screen and (max-width: 520px) {
    .hero-title{
      font-size: 28px;
    }

    .--b{
      margin-bottom: 25px;
    }
  }

  @media screen and (max-width: 1140px) {
    .hero-wrapper{
      padding-left: 50px;
      padding-right: 50px;
    }
  }