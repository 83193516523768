.news-button{
    height: fit-content;
    width: 80%;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    border: 0.8px solid #707070;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    color: black;
}

.left-column-news{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    max-width: 625px;
    margin-top: 10px;
    margin-right: 40px;
}

.news-title{
    margin: 0 !important;
    text-align: left !important;
}

.news-image{
    object-fit: cover;
    width: 326px;
    height: 326px;
}

.description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
    margin-top: 20px;
}

@media screen and (max-width: 700px) {
    .news-button{
        flex-direction: column-reverse;
        margin: auto;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: none;
        
    }
    .left-column-news{
        align-items: center;
        margin: auto;   
        margin-top: 20px;    
    }

    .news-image{
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
    }
    .news-title{
        text-align: center !important;
    }
}