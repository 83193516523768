.contact {
  width: 100%;
}

.contact-hero {
  height: 620px;
  width: 100%;
  background-image: url("/public/images/16.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* The alert message box */
.alert {
  padding: 20px;
  width: 100%;
  position: fixed;
  background-color: #ebebeb; /* Red */
  color: black;
  bottom: 0px;
  transition: all none 0.8s;
  z-index: 999;
}
.alert--hide {
  opacity: 0;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: var(--accent);
}

.contact-pageContent {
  padding-bottom: 80px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 140px;
  height: fit-content;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contactForm-container {
  display: flex;
  flex-direction: column;
}

.contactForm-container form {
  display: flex;
  flex-direction: column;
  max-width: 860px;
  margin-top: 15px;
  z-index: 99;
}

.contactForm-container .h1-title {
  margin-left: 0 !important;
  margin-bottom: 20px !important;
  margin-top: 0px !important;
  text-align: left;
}

form input {
  max-width: 362px;
  margin-bottom: 40px;
  height: 60px;
  font-size: 24px;
  padding-left: 20px;
  z-index: 99;
}
form textarea {
  width: 550px;
  height: 310px;
  margin-bottom: 70px;
  padding: 20px;
  font-size: 24px;
  resize: none;
}

.contact-container {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
  height: fit-content;
  width: fit-content;
}

.field-req {
  font-size: 14px;
  margin-bottom: 4px;
}
@media screen and (max-width: 1300px) {
  form textarea {
    width: auto;
    height: 310px;
    margin-bottom: 70px;
    padding: 20px;
    font-size: 24px;
    resize: none;
  }
}

@media screen and (max-width: 970px) {
  .contact-pageContent {
    padding-left: 40px;
    padding-right: 40px;
  }
  .contact-pageContent {
    flex-direction: column;
  }

  .contact-container {
    margin-left: 0;
    margin-top: 100px;
  }
}

@media screen and (max-width: 450px) {
  .h1-title {
    font-size: 30px;
  }

  .contactForm-container {
    align-items: center;
  }
}
