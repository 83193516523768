:root {
    --primary: #fff;
  }
  
  .btn {
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: transparent;
    color: white;
    border: none;
  }

  .btn--medium {
    font-size: 28px;
    display: inline-block;
    position: relative;
  }

  .btn--small{
    font-size: 18px;
    display: inline-block;
    position: relative;
  }

  .btn--outline {
    background-color: transparent;
    color: var(--accent);
    padding: 8px 0px;
    transition: all 0.3s ease-out;
  }
  

  .btn--medium:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .btn--medium:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @media screen and (max-width: 520px) {
    .btn--medium {
      font-size: 18px;
      display: inline-block;
      position: relative;
    }
  }