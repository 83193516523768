.contact-info-container{
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-bottom: 40px;
}

.icon-title-container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 16PX;
}

.icon-title-container img{
    max-height: 38px;
    margin-right: 5px;
    width: auto;
    object-fit: contain;
}

.contact-info-title{
    font-size: 32px;
    font-weight: normal;
}

.contact-info-container p{
    font-size: 14px;
    margin-bottom: 4px;
}

@media screen and (max-width: 970px) {
    .contact-info-title{
        font-size: 30px;
    }
    .contact-info-container p{
        text-align: left;
    }

    .icon-title-container img{
        max-height: 30px;
    }
}