.service-container {
  width: 100%;
  height: fit-content;
  padding-top: 110px;
  padding-bottom: 110px;
  padding-left: 100px;
  padding-right: 100px;
}

.service-text-wrapper--invert p {
  font-size: 16px !important;
}

.service-text-wrapper p {
  font-size: 16px !important;
}

.service-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.service-wrapper--invert {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.no-margin-bottom {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  font-size: 34px !important;
}
.service-image {
  max-height: 382px;
  max-width: 573px;
  height: auto;
  width: auto;
  margin-right: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.service-image--invert {
  max-height: 382px;
  max-width: 573px;
  height: auto;
  width: auto;
  margin-left: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.service-text-wrapper {
  max-width: 625px;
  min-width: 324px;
  height: fit-content;
}

.service-text-wrapper--invert {
  max-width: 625px;
  min-width: 324px;
  height: fit-content;
}

.service-wrapper a {
  list-style: none;
  text-decoration: none;
  color: var(--accent);
  text-decoration: underline;
  text-decoration-color: var(--accent);
}
.service-wrapper a:hover {
  transition: all ease-in-out 1s;
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .service-container {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .service-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-wrapper p {
    margin-top: 20px !important;
    font-size: 12px !important;
  }

  .service-wrapper--invert p {
    margin-top: 20px !important;
    font-size: 12px !important;
  }

  .service-wrapper--invert {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-image {
    margin: unset;
    height: unset;
    width: auto;
    object-fit: cover;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .service-image--invert {
    margin: unset;
    height: unset;
    width: auto;
    object-fit: cover;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .service-text-wrapper {
    position: unset;
    margin: auto;
    text-align: center;
    min-width: 0;
  }
  .service-text-wrapper h1 {
    text-align: center !important;
    margin-right: unset;
  }
  .service-text-wrapper--invert h1 {
    text-align: center !important;
    margin-right: unset;
  }
  .service-text-wrapper--invert {
    position: unset;
    margin: auto;
    text-align: center;
    min-width: 0;
  }

  .no-margin-bottom {
    font-size: 28px !important;
  }
}
