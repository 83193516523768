.services {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.services-hero {
  width: 100%;
  height: 380px;
  background: url("/public/images/remodelacao.jpg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-y: -440px;
}

@media screen and (max-width: 500px) {
  .services-hero {
    display: none;
  }
}
