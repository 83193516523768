.mv-section {
  width: 100%;
  height: fit-content;
}

.mission {
  background: white;
  width: 100%;
  height: fit-content;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 40px;
  padding-right: 40px;
}

.mission-wrapper {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1240px;
  align-items: center;
}

.left-content {
  font-size: 20px;
  font-weight: normal;
  line-height: 38px;
}

.mission-left {
  position: relative;
  height: fit-content;
}

.mission-wrapper img {
  margin-left: 100px;
}

.h1-title {
  font-size: 32px;
  margin-bottom: 25px;
}

.align-left {
  text-align: left !important;
  margin-left: 0 !important;
}

.vision {
  background: var(--grey);
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.vision-wrapper {
  margin: auto;
  max-width: 1320px;
  padding-left: 40px;
  padding-right: 40px;
}

.vision-wrapper .h1-title {
  color: var(--accent);
}

.vision-content {
  color: white;
  font-size: 20px;
  line-height: 38px;
}

@media screen and (max-width: 450px) {
  .vision-wrapper {
    padding-left: unset;
    padding-right: unset;
  }

  .mission {
    padding-left: unset;
    padding-right: unset;
  }
}

@media screen and (max-width: 1020px) {
  .mission {
    height: fit-content;
    padding-top: 60px;
  }

  .h1-title {
    text-align: center;
    font-size: 30px;
  }

  .mission-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 14px !important;
    line-height: normal;
    text-align: center;
  }

  .mission-wrapper img {
    padding-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
    max-width: 90%;
    max-width: 90%;
  }

  .mission {
    padding-bottom: 10%;
  }

  .left-content {
    margin-top: 20px;
  }
  .align-left {
    text-align: center !important;
    margin: auto !important;
  }
}
