.news-background{
    width: 100%;
    background-color: var(--grey);
    padding-top: 80px;
    min-height: 68.8vh;
}

.news-item-page{
    background-color: white;
    width: 100%;
    height: 100%;
}

.news-item{
    max-width: 1508px;
    height: fit-content;
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    text-decoration: none;
    color: black;
}

.content{
    margin-top: 20px;
    line-height: 2;
}

.left-column-news{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    max-width: 625px;
    margin-top: 10px;
    margin-right: 40px;
}

.news-title{
    margin: 0 !important;
}

.news-image{
    object-fit: cover;
    max-width: 326px;
    max-height: 326px;
}

@media screen and (max-width: 700px) {
    .news-item{
        flex-direction: column-reverse;
        margin: auto;
        align-items: center;
    }
    .left-column-news{
        align-items: center;
        margin: auto;   
        margin-top: 20px;    
    }
}