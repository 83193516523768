.portfolio{
    height: fit-content;
}

.portfolio-hero{
    height: 620px;
    width: 100%;
    background-image: url('/public/images/portfolio.jpg');
    background-position: top right;
    background-size: 2050px;
    background-repeat: no-repeat;
}

.h1-title{
    text-align: center;
    margin: 80px;
}

.portfolio-content{
    max-width: 1720px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 600px) {
    .portfolio-hero{
        background-image: none;
        background-color: var(--grey);
        height: 80px;
    }
  }