.portfolio-button{
    max-width: 100%;
    height: 540px;
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolio-component{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease 0.2s;
}

.portfolio-button .h1-title{
    position: absolute;
    text-align: center;
    color: white;
    transition: all ease 0.2s;
    opacity: 0;
    z-index: 1;
}
.portfolio-button:hover .portfolio-component{
    filter: brightness(70%);
}

.portfolio-button:hover .h1-title{
    opacity: 1;
}
