.noticias{
    height: fit-content;
}

.noticias-hero{
    height: 620px;
    width: 100%;
    background-image: url('/public/images/noticias.jpg');
    background-position: center;
    background-size: 2050px;
    background-repeat: no-repeat;
}

.h1-title{
    text-align: center;
    margin: 80px;
}

.noticias-content{
    max-width: 1720px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

@media screen and (max-width: 600px) {
    .noticias-hero{
        background-image: none;
        background-color: var(--grey);
        height: 80px;
    }
  }