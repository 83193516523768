*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
}

:root{
  --grey: #333333;
  --accent: #D96930;
  --darkblue: #2E2E40;
  --white: #fff;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.hero-title{
  color: white;
  font-size: 52px;
}

h1{
  font-weight: normal;
}

@media screen and (max-width: 1160px) {
  p{
    font-size: 14px !important;
  }
}
@media screen and (max-width: 500px) {
   
  p{
    font-size: 12px !important;
    line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
  }
}
