.wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.wrapper img{
    object-fit: cover;
    width: 50%;
    height: auto;
    filter: grayscale(80%);
    transition: all 0.5s;
}

.wrapper img:hover{
    filter: grayscale(0%);
}

@media screen and (max-width: 650px) {
    .wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .wrapper img{
        width: 100%;
    }
  }
  
