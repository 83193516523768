.item-background{
    width: 100%;
    background-color: var(--grey);
    padding-top: 80px;
}

.portfolio-item{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    background-color: white;
    
}

.portfolio-image{
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 20px;
    margin-left: 20px;
    
}

@media screen and (max-width: 500px) {
    .portfolio-item-title{
        margin-bottom: 20px !important;
    }
}

