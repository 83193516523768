.footer {
  width: 100%;
  height: fit-content;
  background-color: var(--grey);
  z-index: 99;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.social-row {
  display: flex;
  flex-direction: row;
  height: 35px;
  margin: auto;
  width: fit-content;
}

.logo-container {
  margin: auto;
  width: 220px;
}

.logo-container img {
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.legal-container a {
  text-decoration: underline;
  color: white;
  font-size: 0.875rem !important;
  margin: 10px;
}
.legal-container p {
  color: white;
  font-size: 0.875rem !important;
  margin: 10px !important;
}

.legal-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  align-items: center;
}

.message-button {
  max-width: 200px;
  margin: auto;
  margin-top: 10px;
  text-decoration: none;
}

.message-button a {
  color: white;
}
