.parceiros {
  width: 100%;
}

.parceiros-img {
  width: 100%;
  height: 564px;
  background-position: center;
  background-size: cover;
  background-image: url("/public/images/teto\ coplusa.jpg");
  display: flex;
  align-items: center;
}

.parceiros-texto-wrapper {
  margin: auto;
  max-width: 1720px;
  background-color: rgba(0, 0, 0, 0.45);
  height: 275px;
  display: flex;
  align-items: center;
}

.parceiros-texto {
  text-align: center;
  color: white;
  font-size: 36px;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 20px;
}

.parceiros-container {
  padding-top: 100px;
  padding-bottom: 100px;
  height: fit-content;
  background: var(--grey);
}

.parceiros-wrapper {
  margin: auto;
  max-width: 1720px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(7, 1fr);
}

@media screen and (max-width: 1650px) {
  .parceiros-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width: 1400px) {
  .parceiros-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .parceiros-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
